import { del, get, post, postFile } from "./api_helper"

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const token = localStorage.getItem("token")
  if (token) return token
  return null
}

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null
}

// Auth Methods
const postJwtLogin = data => post("login/authenticate", data)
const postJwtLoginNewApi = data => post("users/login", data, {}, true)
const lostPassword = data => post("login/LostPassword", data)
const lostPasswordNewApi = data => post("users/requestPassword", data, {}, true)
const recoverPassword = data => post("login/RecoverPassword", data)
const recoverPasswordNewApi = data =>
  post("users/recoverPassword", data, {}, true)
const refreshToken = () => post("Users/refreshToken")

// Users Methods
const whoIAm = () => get("Users/WhoAmI")
const whoIamNewApi = () => get("users/whoiam", {}, true)
const getUsers = () => get("Users")
const getUsersNewApi = id => get(`users?uid=${id}`, {}, true)
const getUsersByRole = idRol => get(`Users/GetByProfile?idRol=${idRol}`)
const getUsersByRoleNewApi = idRol => get(`users/profile/${idRol}`, {}, true)
const getUser = id => get(`Users/${id}`)
const getUserNewApi = id => get(`users/single/${id}`, {}, true)
const addUser = data => post("Users/Add", data)
const addUserNewApi = data => post("users/add", data, {}, true)
const updateUser = data => post("Users/Update", data)
const updateUserNewApi = data =>
  post(`users/edit/${data.idUsuario}`, data, {}, true)
const changeUserPassword = data => post("Users/ChangePassword", data)
const changeUserPasswordNewApi = data =>
  post("users/change-password", data, {}, true)
const activateDeactivateUser = data => post("Users/activateDeactivate", data)
const activateDeactivateUserNewApi = data =>
  post("users/activate-deactivate", data, {}, true)
const updateProfilePictureNewApi = props =>
  postFile(`users/image/${props.idUsuario}`, props.form, {}, true)
const postAcceptPrivacyTyc = data => post("Users/AceptTYCAndPrivacity", data)
const postAcceptPrivacyTycNewApi = data =>
  post("users/accept-tyc", data, {}, true)
const sendUserToTrash = userId => post(`users/trash/${userId}`, {}, {}, true)

const changePosition = data =>
  post(`users/update-position/${data.userId}`, data, {}, true)
const changeAboutMe = data =>
  post(`users/update-about-me/${data.userId}`, data, {}, true)

// Profiles Methods
const getProfiles = () => get("Profiles")
const getProfile = id => get(`Profiles/${id}`)
const addProfile = data => post("Profiles/Add", data)
const updateProfile = data => post("Profiles/Update", data)

// Groups Methods
const getGroups = () => get("Groups")
const getGroupsNewApi = () => get("groups", {}, true)
const getGroup = id => get(`Groups/${id}`)
const getGroupNewApi = id => get(`groups/${id}`, {}, true)
const addGroup = data => post("Groups/Add", data)
const updateGroup = data => post("Groups/Update", data)

// Positions Methods
const getPositions = () => get("Positions")
const getAllPositionsNewApi = () => get("positions/all", {}, true)
const getPosition = id => get(`Positions/${id}`)
const addPosition = data => post("Positions/Add", data)
const updatePosition = data => post("Positions/Update", data)

// Clients
const getClients = () => get("Clients")
const getClient = id => get(`Clients/${id}`)
const addClient = data => post("Clients/Add", data)
const updateClient = data => post("Clients/Update", data)
const getCustomersNewApi = active =>
  get(`customers/${active ? "active" : "all"}`, {}, true)
const getCustomerNewApi = id => get(`customers/${id}`, {}, true)
const addCustomerNewApi = data => post(`customers/create`, data, {}, true)
const updatecustomerNewApi = data =>
  post(`customers/update/${data.customerId}`, data, {}, true)

// Businesses
const getBusinesses = () => get("Business")
const getBusiness = id => get(`Business/${id}`)
const addBusiness = data => post("Business/Add", data)
const updateBusiness = data => post("Business/Update", data)

// Commercial areas
const getShoppingAreas = () => get("ZonaComercial")
const getShoppingArea = id => get(`ZonaComercial/${id}`)
const addShoppingArea = data => post("ZonaComercial/Add", data)
const updateShoppingArea = data => post("ZonaComercial/Update", data)

// Knowledge categories
const getKnowledgeCategories = () => get("KnowledgeCategory")
const getKnowledgeCategory = id => get(`KnowledgeCategory/${id}`)
const addKnowledgeCategory = data => post("KnowledgeCategories/Add", data)
const updateKnowledgeCategory = data => post("KnowledgeCategories/Update", data)

// Skills
const getSkills = () => get("Abilities")
const getSkill = id => get(`Abilities/${id}`)
const addSkill = data => post("Abilites/Add", data)
const updateSkill = data => post("Abilites/Update", data)

// Levels
const getLevels = () => get("Levels")
const getLevel = id => get(`Levels/${id}`)
const addLevel = data => post("Levels/Add", data)
const updateLevel = data => post("Levels/Update", data)

// Course knowledges
const getCourseKnowledges = () => get("CourseKnowledge")
const getCourseKnowledge = id => get(`CourseKnowledge/${id}`)
const addCourseKnowledge = data => post("CourseKnowledge/Add", data)
const updateCourseKnowledge = data => post("CourseKnowledge/Update", data)

// Support material types
const getSupportMaterialTypes = () => get("SuportMaterialType/Get")
const getSupportMaterialType = id => get(`SuportMaterialType/Get/${id}`)
const insertSupportMaterialType = data => post("SuportMaterialType/Add", data)
const updateSupportMaterialType = data =>
  post("SuportMaterialType/Update", data)

// Courses
const getCourses = () => get("Courses")
const getCoursesNewApi = () => get("courses", {}, true)
const addCourse = data => post("Courses/Add", data)
const getCourse = id => get(`Courses/${id}`)
const getCourseNewApi = id => get(`courses/single/${id}`, {}, true)
const updateCourse = data => post(`Courses/Update`, data)
const updateCourseStatus = (idCourse, status) =>
  get(`Courses/UpdateStatus?idCourse=${idCourse}&status=${status}`)
const uploadImage = data => post("Courses/UploadImage", data)
const getCoursesByUser = idUser =>
  get(`Courses/GetCoursesByUser?idUser=${idUser}`)
const sendCourseToTrash = courseId =>
  post(`courses/trash/${courseId}`, {}, {}, true)

const getCourseByUser = (idCourse, idUser) =>
  get(`Courses/GetCourseByUser?idUser=${idUser}&idCourse=${idCourse}`)
const startCourse = payload => post(`Courses/Start`, payload)

// Lessons
const getLessons = idCourse => get(`Courses/Lessons?idCourse=${idCourse}`)
const getOneLesson = idLesson => get(`Courses/Lessons?idLesson=${idLesson}`)
const addLesson = data => post(`Courses/Lessons/Add`, data)
const updateLesson = data => post(`Courses/Lessons/Update`, data)
const deleteLesson = id => post(`Courses/Lessons/Delete?idLesson=${id}`)
const updateLessonPositions = data =>
  post(`Courses/Lessons/UpdatePositions`, data)
const startLesson = payload => post(`Courses/Lessons/Start`, payload)
const finishLesson = payload => post(`Courses/Lessons/Finish`, payload)
const participantLesson = idCourse =>
  get(`Courses/Lessons/ParticipantAssigned?idCourse=${idCourse}`)

// Lesson blocks
const getBlocks = idLesson => get(`Courses/Lessons/Blocks?idLesson=${idLesson}`)
const getBlock = idBlock => get(`Courses/Lessons/Blocks?idBlock=${idBlock}`)
const addBlock = data => post(`Courses/Lessons/Blocks/Add`, data)
const updateBlock = data => post(`Courses/Lessons/Blocks/Update`, data)
const deleteBlock = id => post(`Courses/Lessons/Blocks/Delete?idBlock=${id}`)

// Support materials
const getSupportMaterials = idCourse =>
  get(`Courses/GetSupportMaterials?idCourse=${idCourse}`)
const deleteSupportMaterial = id =>
  post(`Courses/DeleteSupportMaterial?idSupportMaterial=${id}`)
const addSupportMaterial = payload => {
  const form = payload.form
  const idCurso = payload.request.idCourse
  const title = payload.request.title
  const idSupportMaterialType = payload.request.idSupportMaterialType
  return postFile(
    `Courses/UploadSupportFile?idCourse=${idCurso}&title=${title}&idSupportMaterialType=${idSupportMaterialType}`,
    form
  )
}

// Quizzes
const getQuizzes = idCourse => get(`Quizzes/GetByCourse?idCourse=${idCourse}`)
const getQuizz = idQuizz => get(`Quizzes/${idQuizz}`)
const addQuizz = data => post(`Quizzes/Add`, data)
const updateQuizz = data => post(`Quizzes/Update`, data)
const getQuizzAnswers = idQuizz => get(`Quizzes/Answers/Get?id=${idQuizz}`)
const addQuizzAnswer = data => post(`Quizzes/Answers/Add`, data)
const updateQuizzAnswer = data => post(`Quizzes/Answers/Update`, data)
const deleteQuizzAnswer = id => post(`Quizzes/Answers/Delete`, id)

const getQuizzesNewApi = data =>
  get(`quizzes/${data.lessonId}/${data.userId}`, {}, true)
const addQuizzNewApi = data => post(`quizzes/create`, data, {}, true)
const updateQuizzNewApi = data => post(`quizzes/update`, data, {}, true)
const deleteQuizzNewApi = data => post(`quizzes/delete`, data, {}, true)
const addQuizzAnswerNewApi = data =>
  post("quizzes/answers/create", data, {}, true)
const updateQuizzAnswerNewApi = data =>
  post("quizzes/answers/update", data, {}, true)
const deleteQuizzAnswerNewApi = data =>
  post("quizzes/answers/delete", data, {}, true)
const getQuizzAnswerNewApi = data =>
  get(`quizzes/answers/${data.quizzId}/${data.userId}`, {}, true)
const getQuizzAnswerUserNewApi = data =>
  get(`quizzes/answers/users/${data.quizzId}/${data.userId}`, {}, true)
const addQuizzAnswerUserNewApi = data =>
  post("quizzes/answers/users/create", data, {}, true)

// Exam
const getExam = idCourse => post(`Courses/Exams/ByCourse?idCourse=${idCourse}`)
const addExam = form => post(`Courses/Exams/Add`, form)
const updateExam = form => post(`Courses/Exams/Update`, form)

const startExam = form => post(`Courses/Exams/Try/Start`, form)
const finishExam = form => post(`Courses/Exams/Try/Finish`, form)
const answerQuestionExam = form => post(`Courses/Exams/Try/Answer`, form)

// Question
const getQuestions = idExam =>
  get(`Courses/Exams/Questions/All?idExam=${idExam}`)
const getQuestion = idQuestion =>
  get(`Courses/Exams/Questions/Single?idQuestion=${idQuestion}`)
const addQuestion = form => post(`Courses/Exams/Questions/Add`, form)
const updateQuestion = form => post(`Courses/Exams/Questions/Update`, form)
const deleteQuestion = id => post(`Courses/Exams/Questions/Delete`, { id: id })

// Answers
const getAnswers = idQuestion =>
  get(`Courses/Exams/Questions/Answers/All?idQuestion=${idQuestion}`)
const getAnswer = idAnswer =>
  get(`Courses/Exams/Questions/Answers/Single?idAnswer=${idAnswer}`)
const addAnswer = form => post(`Courses/Exams/Questions/Answers/Add`, form)
const addAnswersMulti = form =>
  post(`Courses/Exams/Questions/Answers/AddMultiple`, form)
const updateAnswer = form =>
  post(`Courses/Exams/Questions/Answers/Update`, form)
const deleteAnswer = id =>
  post(`Courses/Exams/Questions/Answers/Delete`, { id: id })

// Groups participants
const getGroupParticipants = idGroup =>
  get(`Groups/GetGroupParticipants?idGroup=${idGroup}`)
const getGroupParticipantsNewApi = idGroup =>
  get(`groups/participants/all?groupId=${idGroup}`, {}, true)
const getGroupCourses = payload => {
  const group = payload.idGroup ?? 0
  const course = payload.idCourse ?? 0
  return get(`Groups/GetGroupCourses?idGroup=${group}&idCourse=${course}`)
}
const getGroupCoursesNewApi = payload =>
  get(`groups/courses/all?groupId=${payload.idGroup}`, {}, true)

const getCoursegroupsNewApi = payload =>
  get(`courses/groups/all?courseId=${payload.idCourse}`, {}, true)
const insertGroupParticipant = data => post(`Groups/AddParticipant`, data)
const deleteGroupParticipant = data => post(`Groups/RemoveParticipant`, data)
const insertGroupCourse = data => post(`Groups/AddCourse`, data)
const deleteGroupCourse = data => post(`Groups/RemoveCourse`, data)
const resetGroupCourseApi = data =>
  get(`Courses/Reset/AllGroup?Group=${data.idGroup}&Course=${data.idCourse}`)
const resetUserGroupCourseApi = data =>
  get(
    `Courses/Reset/SingleUser?Group=${data.idGroup}&Course=${data.idCourse}&User=${data.idUser}`
  )
const resetGroupUserNewApi = data => post(`users/courses/reset`, data, {}, true)

// Polls
const getPolls = idCourse => get(`Poll/GetByCourse?idCourse=${idCourse}`)
const insertPoll = data => post(`Poll/Add`, data)
const updatePoll = data => post(`Poll/Update`, data)
const getPollAnswers = idPoll =>
  get(`Poll/Answers/GetByCourse?idCourse=${idPoll}`)
const insertPollAnswer = data => post(`Poll/Answers/Add`, data)
const insertMassivePollAnswer = data => post(`Poll/Answers/AddMassive`, data)
const updatePollAnswer = data => post(`Poll/Answers/Update`, data)
const deletePollAnswer = data => post(`Poll/Answers/Delete`, data)
const tryPoll = data =>
  post(
    `Poll/AddPollUserAnswerMassive
`,
    data
  )

// Favourites
const addFav = idCourse => post(`Courses/Favourite/Add?idCourse=${idCourse}`)
const removeFav = idCourse =>
  post(`Courses/Favourite/Remove?idCourse=${idCourse}`)

// Notes
const insertNote = data => post(`Courses/Lessons/Notes/Add`, data)
const deleteNote = idNote =>
  post(`Courses/Lessons/Notes/Remove`, { idNote: idNote })
const getNotes = idCourse => get(`Courses/Lessons/Notes?idCourse=${idCourse}`)

// Images
const addImage = file => postFile("Courses/UploadImage", file)

// Reports
const report1 = form => post(`Reports/Reporte1`, form)
const report1NewApi = form => post(`reports/report1`, form, {}, true)
const report2 = form => post(`Reports/Reporte2`, form)
const report2NewApi = form => post(`reports/report2`, form, {}, true)
const report3 = form => post(`Reports/Reporte3`, form)
const report3NewApi = form => post(`reports/report3`, form, {}, true)
const report4 = form => post(`Reports/Reporte4`, form)
const report4NewApi = form => post(`reports/report4`, form, {}, true)
const report5 = form => post(`Reports/Reporte5`, form)
const report5NewApi = form => post(`reports/report5`, form, {}, true)
const report6 = form => post(`Reports/Reporte6`, form)
const report6NewApi = form => post(`reports/report6`, form, {}, true)
const reportANewApi = form => post(`reports/reportA`, form, {}, true)
const reportb = form => post(`Reports/ReporteRCB`, form)
const reportBNewApi = form => post(`reports/reportB`, form, {}, true)
const report9NewApi = form => post(`reports/report9`, form, {}, true)
const reportSurveyNewApi = form => post(`reports/reportSurvey`, form, {}, true)

// New requests
const abilitiesWithGroup = form =>
  post(`users/abilitiesWithGroup`, form, {}, true)
const coursesWithGroup = form => post(`users/coursesWithGroup`, form, {}, true)
const resetCoursesWithGroup = form =>
  post(`users/resetCoursesWithGroup`, form, {}, true)

// Interfester
const getLocations = () => get(`interfester/locations`, {}, true)
const addEventImage = file =>
  postFile("interfester/events/image", file, {}, true)
const getAllEvents = () => get(`interfester/events/all`, {}, true)
const insertEvent = data => post(`interfester/events/add`, data, {}, true)
const editEvent = data =>
  post(`interfester/events/edit/${data.eventId}`, data, {}, true)
const removeEvent = id => post(`interfester/events/delete/${id}`, {}, {}, true)

// Soft skills abilities
const getSoftskillsAbilities = () => get(`soft-skills/abilities`, {}, true)
const getSoftskillsAbility = id =>
  get(`soft-skills/abilities/single/${id}`, {}, true)
const addSoftskillsAbility = data =>
  post(`soft-skills/abilities/create`, data, {}, true)
const updateSoftskillsAbility = data =>
  post(`soft-skills/abilities/update/${data.abilityId}`, data, {}, true)
const addSoftskillsAbilityUser = data =>
  post(`soft-skills/abilities/users/${data.abilityId}/add`, data, {}, true)
const removeSoftskillsAbilityUser = data =>
  post(`soft-skills/abilities/users/${data.abilityId}/remove`, data, {}, true)
const updateSoftskillsAbilityUserPosition = data =>
  post(`soft-skills/abilities/users/${data.userId}/order`, data, {}, true)

// Soft skills courses
const getSoftskillsCourses = () => get(`soft-skills/courses`, {}, true)
const getSoftskillsCourse = id =>
  get(`soft-skills/courses/single/${id}`, {}, true)
const addSoftskillsCourse = data =>
  post(`soft-skills/courses/create`, data, {}, true)
const updateSoftskillsCourse = data =>
  post(`soft-skills/courses/update/${data.courseId}`, data, {}, true)
const sendSoftskillsCourseToTrash = data =>
  post(`soft-skills/courses/trash/${data.courseId}`, data, {}, true)
const changeSoftskillsCourseStatus = data =>
  post(`soft-skills/courses/status/${data.courseId}`, data, {}, true)

// Soft skills user courses
const getSoftskillsUserCourses = userId =>
  get(`soft-skills/users/${userId}/my-courses`, {}, true)
const startSoftskillsUserCourse = data =>
  post(`soft-skills/users/${data.userId}/start-course`, data, {}, true)
const finishSoftskillsUserCourse = data =>
  post(`soft-skills/users/${data.userId}/finish-course`, data, {}, true)
const getSoftskillsUserProgress = userId =>
  get(`soft-skills/users/${userId}/progress-detail`, {}, true)
const updateSoftskillsUser = data =>
  post(`soft-skills/users/${data.userId}/update`, data, {}, true)

// Charts
const yearData = [
  {
    name: "Completado",
    data: [67, 55, 41, 67, 22, 97, 36],
  },
  {
    name: "En proceso",
    data: [13, 23, 20, 8, 57, 3, 31],
  },
  {
    name: "Sin iniciar",
    data: [20, 22, 39, 25, 21, 0, 33],
  },
]

const monthData = [
  {
    name: "Completado",
    data: [90, 100, 85, 55, 41, 80, 21],
  },
  {
    name: "En proceso",
    data: [1, 0, 13, 29, 47, 5, 30],
  },
  {
    name: "Sin iniciar",
    data: [9, 0, 3, 24, 12, 15, 49],
  },
]

const weekData = [
  {
    name: "Completado",
    data: [74, 52, 61, 47, 72, 53, 88],
  },
  {
    name: "En proceso",
    data: [13, 23, 20, 38, 13, 27, 9],
  },
  {
    name: "Sin iniciar",
    data: [13, 25, 19, 15, 15, 20, 3],
  },
]

const getWeeklyData = () => weekData
const getYearlyData = () => yearData
const getMonthlyData = () => monthData

export {
  getLoggedInUser,
  isUserAuthenticated,
  postJwtLogin,
  postJwtLoginNewApi,
  refreshToken,
  lostPassword,
  lostPasswordNewApi,
  recoverPassword,
  recoverPasswordNewApi,
  getUsers,
  getUsersByRole,
  getUser,
  addUser,
  updateUser,
  changeUserPassword,
  activateDeactivateUser,
  postAcceptPrivacyTyc,
  getProfiles,
  getProfile,
  addProfile,
  updateProfile,
  sendUserToTrash,
  changePosition,
  changeAboutMe,
  getGroups,
  getGroupsNewApi,
  getGroup,
  getGroupNewApi,
  addGroup,
  updateGroup,
  resetGroupUserNewApi,
  getPositions,
  getAllPositionsNewApi,
  getPosition,
  addPosition,
  updatePosition,
  getClients,
  getClient,
  addClient,
  getCustomersNewApi,
  getCustomerNewApi,
  addCustomerNewApi,
  updatecustomerNewApi,
  updateClient,
  whoIAm,
  getWeeklyData,
  getYearlyData,
  getMonthlyData,
  getBusinesses,
  getBusiness,
  addBusiness,
  updateBusiness,
  getShoppingAreas,
  getShoppingArea,
  addShoppingArea,
  updateShoppingArea,
  getKnowledgeCategories,
  getKnowledgeCategory,
  addKnowledgeCategory,
  updateKnowledgeCategory,
  getLevels,
  getLevel,
  addLevel,
  updateLevel,
  getSkills,
  getSkill,
  addSkill,
  updateSkill,
  getSupportMaterialTypes,
  getSupportMaterialType,
  insertSupportMaterialType,
  updateSupportMaterialType,
  getCourseKnowledges,
  getCourseKnowledge,
  addCourseKnowledge,
  updateCourseKnowledge,
  getCourses,
  getCoursesNewApi,
  getCoursesByUser,
  sendCourseToTrash,
  getCourseByUser,
  addCourse,
  updateCourse,
  updateCourseStatus,
  getCourse,
  getCourseNewApi,
  startCourse,
  uploadImage,
  getLessons,
  getOneLesson,
  addLesson,
  updateLesson,
  deleteLesson,
  participantLesson,
  updateLessonPositions,
  getBlocks,
  getBlock,
  addBlock,
  updateBlock,
  deleteBlock,
  addImage,
  getGroupParticipants,
  getGroupParticipantsNewApi,
  getGroupCourses,
  getGroupCoursesNewApi,
  resetGroupCourseApi,
  resetUserGroupCourseApi,
  insertGroupParticipant,
  deleteGroupParticipant,
  insertGroupCourse,
  deleteGroupCourse,
  getSupportMaterials,
  addSupportMaterial,
  deleteSupportMaterial,
  getQuizzes,
  getQuizz,
  addQuizz,
  updateQuizz,
  getQuizzAnswers,
  addQuizzAnswer,
  updateQuizzAnswer,
  deleteQuizzAnswer,
  getExam,
  addExam,
  updateExam,
  getQuestions,
  getQuestion,
  addQuestion,
  updateQuestion,
  deleteQuestion,
  getAnswers,
  getAnswer,
  addAnswer,
  addAnswersMulti,
  updateAnswer,
  deleteAnswer,
  getPolls,
  insertPoll,
  updatePoll,
  getPollAnswers,
  insertPollAnswer,
  insertMassivePollAnswer,
  updatePollAnswer,
  deletePollAnswer,
  tryPoll,
  addFav,
  removeFav,
  insertNote,
  deleteNote,
  getNotes,
  startLesson,
  finishLesson,
  startExam,
  finishExam,
  answerQuestionExam,
  report1,
  report1NewApi,
  report2,
  report2NewApi,
  report3,
  report3NewApi,
  report4,
  report4NewApi,
  report5,
  report5NewApi,
  report6,
  report6NewApi,
  reportANewApi,
  reportb,
  reportBNewApi,
  report9NewApi,
  reportSurveyNewApi,
  abilitiesWithGroup,
  coursesWithGroup,
  resetCoursesWithGroup,
  /* ------ NEW API ------- */
  whoIamNewApi,
  getUsersNewApi,
  getUsersByRoleNewApi,
  getUserNewApi,
  addUserNewApi,
  updateUserNewApi,
  changeUserPasswordNewApi,
  activateDeactivateUserNewApi,
  updateProfilePictureNewApi,
  postAcceptPrivacyTycNewApi,
  getQuizzesNewApi,
  addQuizzNewApi,
  updateQuizzNewApi,
  deleteQuizzNewApi,
  addQuizzAnswerNewApi,
  updateQuizzAnswerNewApi,
  deleteQuizzAnswerNewApi,
  getQuizzAnswerNewApi,
  getQuizzAnswerUserNewApi,
  addQuizzAnswerUserNewApi,
  getCoursegroupsNewApi,
  getLocations,
  getAllEvents,
  insertEvent,
  editEvent,
  removeEvent,
  addEventImage,
  getSoftskillsAbilities,
  getSoftskillsAbility,
  addSoftskillsAbility,
  updateSoftskillsAbility,
  addSoftskillsAbilityUser,
  removeSoftskillsAbilityUser,
  getSoftskillsCourses,
  getSoftskillsCourse,
  addSoftskillsCourse,
  updateSoftskillsCourse,
  sendSoftskillsCourseToTrash,
  changeSoftskillsCourseStatus,
  updateSoftskillsAbilityUserPosition,
  getSoftskillsUserCourses,
  startSoftskillsUserCourse,
  finishSoftskillsUserCourse,
  getSoftskillsUserProgress,
  updateSoftskillsUser,
}
