import { useState } from "react"
import { PropTypes } from "prop-types"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
} from "reactstrap"
import classnames from "classnames"
import { Link } from "react-router-dom"
import CoursesWidget from "./CourseWidget"
import moment from "moment"

const CoursesTab = props => {
  const userId = props.userId
  const myCourses = props.courses ?? []
  const showAll = props.all ?? false

  let coursesInProgress = []
  let coursesNext = []
  let coursesCompleted = []
  let coursesFav = []

  if (myCourses !== undefined) {
    for (let i = 0; i < myCourses.length; i++) {
      const assignDate = myCourses[i].registerDate
      if (myCourses[i].current === true) {
        coursesInProgress = myCourses[i].courses.filter(course => {
          course.assignDate = assignDate
          const courseUser = course.courseUsers.find(
            courseUser => courseUser.userId === userId
          )
          if (courseUser) {
            return courseUser.statusId !== 3
          }
          return true
        })
      } else {
        const coursesNextAbility = myCourses[i].courses.filter(course => {
          course.assignDate = assignDate
          const courseUser = course.courseUsers.find(
            courseUser => courseUser.userId === userId
          )
          if (courseUser) {
            return courseUser.statusId !== 3
          }
          return true
        })
        coursesNext = [...coursesNext, ...coursesNextAbility]
      }

      const coursesCompletedAbility = myCourses[i].courses.filter(course => {
        course.assignDate = assignDate
        const courseUser = course.courseUsers.find(
          courseUser => courseUser.userId === userId
        )
        if (courseUser) {
          return courseUser.statusId === 3
        }
        return false
      })
      if (coursesCompletedAbility !== undefined) {
        coursesCompleted = [...coursesCompleted, ...coursesCompletedAbility]
      }
    }
  }

  const [activeTab, setActiveTab] = useState(props.initialTab ?? "1")

  const toggleIconCustom = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  return props.loading ? (
    <Card>
      <CardBody>
        <div className="mt-3">
          <h2 className="text-center">
            <i className="fa fa-spin fa-spinner"></i> Cargando información...
          </h2>
        </div>
      </CardBody>
    </Card>
  ) : (
    <Card>
      <CardBody>
        <Nav className="icon-tab nav-justified">
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({
                active: activeTab === "1",
              })}
              onClick={() => {
                toggleIconCustom("1")
              }}
            >
              <span className="d-none d-sm-block">
                Cursos habilidad actual ({coursesInProgress.length})
              </span>
              <span className="d-block d-sm-none">
                <i className="fas fa-cogs"></i>
              </span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({
                active: activeTab === "5",
              })}
              onClick={() => {
                toggleIconCustom("5")
              }}
            >
              <span className="d-none d-sm-block">
                Cursos pendientes ({coursesNext.length})
              </span>
              <span className="d-block d-sm-none">
                <i className="fas fa-cogs"></i>
              </span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({
                active: activeTab === "3",
              })}
              onClick={() => {
                toggleIconCustom("3")
              }}
            >
              <span className="d-none d-sm-block">
                Cursos completados ({coursesCompleted.length})
              </span>
              <span className="d-block d-sm-none">
                <i className="fas fa-cogs"></i>
              </span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({
                active: activeTab === "4",
              })}
              onClick={() => {
                toggleIconCustom("4")
              }}
            >
              <span className="d-none d-sm-block">
                Cursos favoritos ({coursesFav.length})
              </span>
              <span className="d-block d-sm-none">
                <i className="fas fa-cogs"></i>
              </span>
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab} className="p-3 text-muted mt-4">
          <TabPane tabId="1">
            <Row>
              {coursesInProgress.length <= 0 && (
                <Col lg={12} className="text-center">
                  <h5 className="text-center">Sin cursos en progreso...</h5>
                </Col>
              )}
              {coursesInProgress
                .sort((a, b) => {
                  return a.ability.abilityName > b.ability.abilityName ? 1 : -1
                })
                .map((course, index) => {
                  const courseUser = course.courseUsers.find(
                    courseUser => courseUser.userId === userId
                  )

                  const started = courseUser !== undefined
                  const courseStart =
                    courseUser !== undefined ? courseUser.createdAt : undefined
                  const finished =
                    courseUser !== undefined ? courseUser.statusId === 2 : false
                  const courseEnd =
                    courseUser !== undefined ? courseUser.finishedAt : undefined

                  return index >= 3 && !showAll ? (
                    <div key={`course-progress-${course.courseId}`}></div>
                  ) : (
                    <Col
                      xl={6}
                      lg={12}
                      md={12}
                      sm={12}
                      key={`course-progress-${course.courseId}`}
                      className="mb-4"
                    >
                      <CoursesWidget
                        id={course.courseId}
                        title={course.courseName}
                        ability={course.ability.abilityName}
                        modality={course.modalityId}
                        minutes={course.duration}
                        limitDays={course.limitDays}
                        image={course.image ?? ""}
                        fav={false}
                        started={started}
                        finished={finished}
                        dateStart={courseStart}
                        dateFinish={courseEnd}
                        assignDate={course.assignDate}
                      />
                    </Col>
                  )
                })}
            </Row>
            {coursesInProgress.length > 3 && !showAll && (
              <Row>
                <Col lg={12} className="text-center">
                  <Link to={`soft-skills/my-courses/1`}>Ver más...</Link>
                </Col>
              </Row>
            )}
          </TabPane>
          <TabPane tabId="5">
            <Row>
              {coursesNext.length <= 0 && (
                <Col lg={12} className="text-center">
                  <h5 className="text-center">Sin cursos siguientes...</h5>
                </Col>
              )}
              {coursesNext
                .sort((a, b) => {
                  return a.ability.abilityName > b.ability.abilityName ? 1 : -1
                })
                .map((course, index) => {
                  const courseUser = course.courseUsers.find(
                    courseUser => courseUser.userId === userId
                  )

                  const started = courseUser !== undefined
                  const courseStart =
                    courseUser !== undefined ? courseUser.createdAt : undefined
                  const finished =
                    courseUser !== undefined ? courseUser.statusId === 2 : false
                  const courseEnd =
                    courseUser !== undefined ? courseUser.finishedAt : undefined

                  return index >= 3 && !showAll ? (
                    <div key={`course-progress-${course.courseId}`}></div>
                  ) : (
                    <Col
                      xl={6}
                      lg={12}
                      md={12}
                      sm={12}
                      key={`course-progress-${course.courseId}`}
                      className="mb-4"
                    >
                      <CoursesWidget
                        id={course.courseId}
                        title={course.courseName}
                        ability={course.ability.abilityName}
                        modality={course.modalityId}
                        minutes={course.duration}
                        limitDays={course.limitDays}
                        image={course.image ?? ""}
                        fav={false}
                        started={started}
                        finished={finished}
                        dateStart={courseStart}
                        dateFinish={courseEnd}
                        assignDate={course.assignDate}
                      />
                    </Col>
                  )
                })}
            </Row>
            {coursesNext.length > 3 && !showAll && (
              <Row>
                <Col lg={12} className="text-center">
                  <Link to={`soft-skills/my-courses/1`}>Ver más...</Link>
                </Col>
              </Row>
            )}
          </TabPane>
          <TabPane tabId="3">
            <Col lg={12}>
              <Row>
                {coursesCompleted.length <= 0 && (
                  <Col lg={12} className="text-center">
                    <h5 className="text-center">Sin cursos completados...</h5>
                  </Col>
                )}
                {coursesCompleted
                  .sort((a, b) => {
                    return a.ability.abilityName > b.ability.abilityName
                      ? 1
                      : -1
                  })
                  .map((course, index) => {
                    const courseUser = course.courseUsers.find(
                      courseUser => courseUser.userId === userId
                    )

                    const started = courseUser !== undefined
                    const courseStart =
                      courseUser !== undefined
                        ? courseUser.createdAt
                        : undefined
                    const finished =
                      courseUser !== undefined
                        ? courseUser.statusId === 2
                        : false
                    const courseEnd =
                      courseUser !== undefined
                        ? courseUser.finishedAt
                        : undefined

                    return index >= 3 && !showAll ? (
                      <div key={`course-completed-${course.courseId}`}></div>
                    ) : (
                      <Col
                        xl={6}
                        lg={12}
                        md={12}
                        sm={12}
                        key={`course-completed-${course.courseId}`}
                        className="mb-4"
                      >
                        <CoursesWidget
                          id={course.courseId}
                          title={course.courseName}
                          ability={course.ability.abilityName}
                          modality={course.modalityId}
                          minutes={course.duration}
                          limitDays={course.limitDays}
                          image={course.image ?? ""}
                          fav={false}
                          started={started}
                          finished={finished}
                          dateStart={courseStart}
                          dateFinish={courseEnd}
                          assignDate={course.assignDate}
                        />
                      </Col>
                    )
                  })}
              </Row>
              {coursesCompleted.length > 3 && !showAll && (
                <Row>
                  <Col lg={12} className="text-center">
                    <Link to={`soft-skills/my-courses/3`}>Ver más...</Link>
                  </Col>
                </Row>
              )}
            </Col>
          </TabPane>
          <TabPane tabId="4">
            <Row>
              {coursesFav.length <= 0 && (
                <Col lg={12} className="text-center">
                  <h5 className="text-center">Sin cursos favoritos...</h5>
                </Col>
              )}
              {coursesFav
                .sort((a, b) => {
                  return a.ability.abilityName > b.ability.abilityName ? 1 : -1
                })
                .map((course, index) => {
                  const courseUser = course.courseUsers.find(
                    courseUser => courseUser.userId === userId
                  )

                  const started = courseUser !== undefined
                  const courseStart =
                    courseUser !== undefined ? courseUser.createdAt : undefined
                  const finished =
                    courseUser !== undefined ? courseUser.statusId === 2 : false
                  const courseEnd =
                    courseUser !== undefined ? courseUser.finishedAt : undefined
                  const courseValidated =
                    courseUser !== undefined
                      ? courseUser.validatedAt
                      : undefined

                  return index >= 3 && !showAll ? (
                    <div key={`course-fav-${course.courseId}`}></div>
                  ) : (
                    <Col
                      xl={6}
                      lg={12}
                      md={12}
                      sm={12}
                      key={`course-fav-${course.courseId}`}
                      className="mb-4"
                    >
                      <CoursesWidget
                        id={course.courseId}
                        title={course.courseName}
                        ability={course.ability.abilityName}
                        modality={course.modalityId}
                        minutes={course.duration}
                        limitDays={course.limitDays}
                        image={course.image ?? ""}
                        fav={true}
                        started={started}
                        finished={finished}
                        dateStart={courseStart}
                        dateFinish={courseEnd}
                        dateValidated={courseValidated}
                        assignDate={course.assignDate}
                      />
                    </Col>
                  )
                })}
            </Row>
            {coursesFav.length > 3 && !showAll && (
              <Row>
                <Col lg={12} className="text-center">
                  <Link to={`soft-skills/my-courses/4`}>Ver más...</Link>
                </Col>
              </Row>
            )}
          </TabPane>
        </TabContent>
      </CardBody>
    </Card>
  )
}

CoursesTab.propTypes = {
  courses: PropTypes.array,
  loading: PropTypes.bool,
  all: PropTypes.bool,
  initialTab: PropTypes.string,
  userId: PropTypes.number,
}

export default CoursesTab
