export const getSoftskillsProfileName = softskillsProfileId => {
  switch (softskillsProfileId) {
    case 1:
      return "S1 - Admin"
    case 2:
      return "S2 - Head of Sales"
    case 3:
      return "S3 - Regional Sales Manager"
    case 4:
      return "S4 - Learner"
    default:
      return "No asignado"
  }
}

export const getSoftskillsPositionName = softskillsPositionId => {
  switch (softskillsPositionId) {
    case 1:
      return "Sales excelence manager"
    case 2:
      return "Regional sales manager"
    case 3:
      return "Technical sales advisor"
    case 4:
      return "Key account executive"
    case 5:
      return "Key account manager"
    case 6:
      return "Otro"
    default:
      return "No asignado"
  }
}

export const getSoftskillsRegionName = softskillsRegionId => {
  switch (softskillsRegionId) {
    case 1:
      return "Admin plataforma"
    case 2:
      return "National"
    case 3:
      return "Negocios institucionales"
    case 4:
      return "Especificaciones"
    case 5:
      return "Bajío"
    case 6:
      return "Centro"
    case 7:
      return "Norte"
    case 8:
      return "Sureste"
    default:
      return "No asignado"
  }
}

export const getAbilityStatus = progress => {
  const pending = progress.courses.filter(course => course.progress === 0)
  if (pending.length === progress.courses.length) return 0
  const finished = progress.courses.filter(course => course.progress === 2)
  if (finished.length === progress.courses.length) return 2
  return 1
}

export const getIndicator = status => {
  if (status === 1) return <span className="indicator indicator-yellow">P</span>
  if (status === 2) return <span className="indicator indicator-orange">R</span>
  if (status === 3) return <span className="indicator indicator-green">C</span>
  return <span className="indicator indicator-red">P</span>
}
