import CoursesTab from "components/SoftSkills/CourseUsers/CoursesTab"
import SoftskillsProfileDashboardBox from "components/SoftSkills/Users/ProfileDashboardBox"
import { Fragment, useEffect, useState } from "react"
import { MetaTags } from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import { getSoftskillsUserCourses, me, meNew } from "store/actions"
import { getAbilityStatus, getIndicator } from "./Helpers/Helpers"

const WelcomeSoftSkills = () => {
  const [activeTab, setActiveTab] = useState("1")
  const dispatch = useDispatch()

  const { user, courses, loading } = useSelector(store => ({
    user: store.ProfileNew.user,
    courses: store.SoftskillsUserCoursesList.courses,
    loading: store.SoftskillsUserCoursesList.loading,
  }))

  const userId = user?.idUsuario ?? 0

  useEffect(() => {
    dispatch(meNew())
  }, [])

  useEffect(() => {
    dispatch(getSoftskillsUserCourses(userId))
  }, [userId])

  return (
    <Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Soft Skills | La Academia Fester</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row>
                    <Col md={12}>
                      <Row>
                        <Col xs={12}>
                          <h3>
                            Bienvenido a la Plataforma para el seguimiento de
                            progreso de Habilidades blandas
                          </h3>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              {user !== undefined && (
                <div className="mb-2">
                  <SoftskillsProfileDashboardBox user={user} />
                </div>
              )}
              <Card>
                <CardBody>
                  <h4 className="text-center">Avance de habilidades</h4>
                  <Row>
                    {(courses || []).map(ability => (
                      <Col
                        sm={6}
                        xs={12}
                        key={`hab-${ability.abilityId}`}
                        className="mb-2"
                      >
                        {getIndicator(ability.statusId)}
                        {ability.ability.abilityName}
                      </Col>
                    ))}
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col md={6}>
              <Card>
                <CardBody>
                  <h4 className="text-center">
                    Avance en cursos de la habilidad en progreso
                  </h4>
                  <Row>
                    {(courses || []).map(ability => {
                      if (ability.current === true) {
                        return ability.courses.map(course => {
                          let statusId = 0
                          if (course.courseUser !== null) {
                            statusId = course.courseUser.statusId
                          }
                          return (
                            <Col
                              md={2}
                              key={`course-${statusId}`}
                              className="mb-2"
                            >
                              {getIndicator(statusId)}
                              {course.courseName}
                            </Col>
                          )
                        })
                      }
                      return <div key={`course-${ability.abilityId}`}></div>
                    })}
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <CoursesTab courses={courses} loading={loading} userId={userId} />
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  )
}

export default WelcomeSoftSkills
