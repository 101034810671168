import React, { Component, Fragment, useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Container, Row, Col, Card, CardBody, Badge } from "reactstrap"

import Breadcrumbs from "../../../components/Common/Breadcrumb2"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"
import { useDispatch, useSelector } from "react-redux"
import {
  finishSoftskillsUserCourse,
  finishSoftskillsUserCourseClean,
  getSoftskillsCourse,
  getSoftskillsUserCourses,
  startSoftskillsUserCourse,
  startSoftskillsUserCourseClean,
} from "store/actions"
import UploadCourseImageModal from "components/SoftSkills/CourseUsers/UploadCourseImageModal"
import UploadCourseTextModal from "components/SoftSkills/CourseUsers/UploadCourseTextModal"
import SweetAlert from "react-bootstrap-sweetalert"

const SoftskillsUserCourseDetail = props => {
  const { courseId } = useParams()

  const [showUploadImage, setShowUploadImage] = useState(false)
  const [showUploadText, setShowUploadText] = useState(false)
  const [showFinishAlert, setShowFinishAlert] = useState(false)
  const [currentAbility, setCurrentAbility] = useState(false)

  const dispatch = useDispatch()

  const {
    user,
    course,
    courses,
    loading,
    courseUser,
    courseUserLoading,
    finishLoading,
    finishDone,
  } = useSelector(store => ({
    user: store.Profile.user,
    courses: store.SoftskillsUserCoursesList.courses,
    course: store.SoftskillCourseGet.course,
    loading: store.SoftskillCourseGet.loading,
    courseUser: store.SoftskillsUserCourseStart.course,
    courseUserLoading: store.SoftskillsUserCourseStart.loading,
    finishLoading: store.SoftskillsUserCourseFinish.loading,
    finishDone: store.SoftskillsUserCourseFinish.done,
  }))

  const userId = user?.idUsuario ?? 0

  const started = courseUser !== undefined
  const courseStart =
    courseUser !== undefined ? courseUser.createdAt : undefined
  const finished = courseUser !== undefined ? courseUser.statusId === 2 : false
  const courseEnd = courseUser !== undefined ? courseUser.finishedAt : undefined

  useEffect(() => {
    if (userId !== 0) {
      dispatch(getSoftskillsCourse(courseId))
      dispatch(startSoftskillsUserCourse({ courseId, userId }))
      return () => {
        dispatch(startSoftskillsUserCourseClean())
      }
    }
  }, [courseId])

  useEffect(() => {
    if (courses.length === 0) {
      dispatch(getSoftskillsUserCourses(userId))
    }
  }, [])

  useEffect(() => {
    courses.forEach(ability => {
      if (ability.current === true) {
        const exists = ability.courses.some(
          c => c.courseId === parseInt(courseId)
        )
        if (exists && !currentAbility) {
          setCurrentAbility(true)
        }
      }
    })
  }, [courses])

  const getModality = modalityId => {
    if (modalityId == 1) return "Online Course"
    if (modalityId == 2) return "Material"
    if (modalityId == 3) return "Playlist"
    if (modalityId == 4) return "Presencial"
    if (modalityId == 5) return "External content"
  }

  const getLanguage = languageId => {
    if (languageId == 1) return "Español"
    if (languageId == 2) return "Inglés"
    if (languageId == 3) return "Portugués"
    if (languageId == 4) return "Alemán"
  }

  const handleFinishCourse = () => {
    if (course?.imageUpload === true) {
      setShowUploadImage(true)
    }
    if (course?.openText === true) {
      setShowUploadText(true)
    }
  }

  const finishCourseWithImage = image => {
    dispatch(
      finishSoftskillsUserCourse({
        courseId: parseInt(courseId),
        userId,
        image,
      })
    )
  }

  const finishCourseWithText = text => {
    dispatch(
      finishSoftskillsUserCourse({ courseId: parseInt(courseId), userId, text })
    )
  }

  if (finishDone) {
    dispatch(finishSoftskillsUserCourseClean())
    dispatch(getSoftskillsCourse(courseId))
    dispatch(getSoftskillsUserCourses(userId))
    dispatch(startSoftskillsUserCourse({ courseId, userId }))
    setShowFinishAlert(true)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
            Detalle de curso {course?.courseName ?? ""} | Soft Skills
          </title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col sm={4}>
              <h4>Detalle del curso</h4>
            </Col>
            <Col sm={8}>
              <Breadcrumbs
                breadcrumbItems={[
                  { key: "dashboard", title: "Inicio", link: "/soft-skills" },
                  {
                    key: "my-courses",
                    title: "Mis cursos",
                    link: "/soft-skills/my-courses",
                  },
                  { key: "my-course", title: course?.courseName ?? "Curso" },
                ]}
              />
            </Col>
          </Row>
          {course !== undefined && courseUser !== undefined && (
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <Row>
                      <Col md={3}>
                        <img src={course.image} className="img-fluid" />
                      </Col>
                      <Col md={9}>
                        <Row>
                          <Col md={9}>
                            <h4 className="text-secondary">
                              {course.ability?.abilityName ?? ""}
                            </h4>
                            <h2 className="text-primary">
                              {course.courseName ?? ""}
                            </h2>
                            <hr />
                            <p>
                              <strong>Modalidad:</strong>{" "}
                              {getModality(course.modalityId)}
                            </p>
                            <p>
                              <strong>Idioma:</strong>{" "}
                              {getLanguage(course.languageId)}
                            </p>
                            <p>
                              <strong>
                                Días límite para finalizar el curso:
                              </strong>{" "}
                              {course.limitDays}
                            </p>
                            <p>
                              <strong>Duración estimada del curso:</strong>{" "}
                              {course.duration} minutos
                            </p>
                            <p>
                              <strong>Tipo de evidencia:</strong>{" "}
                              {course?.imageUpload === true
                                ? "Archivo"
                                : course?.openText === true
                                ? "Texto"
                                : ""}
                            </p>
                          </Col>
                          <Col md={3}>
                            {finished ? (
                              <h3>
                                <span
                                  className="badge bg-pill bg-warning pt-2 pl-2 pr-2"
                                  style={{ display: "block" }}
                                >
                                  En validación
                                </span>
                              </h3>
                            ) : (
                              <h3>
                                <span
                                  className="badge bg-pill bg-secondary pt-2 pl-2 pr-2"
                                  style={{ display: "block" }}
                                >
                                  Pendiente de completar
                                </span>
                              </h3>
                            )}
                          </Col>
                        </Row>
                        <Row>
                          <Col md={9}>
                            <p>
                              <strong>Objetivo del curso</strong>
                            </p>
                            <p>{course.description}</p>
                            <p className="mt-2">
                              <strong>Link para ingresar:</strong>{" "}
                              <a
                                href={course.link}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {course.link}
                              </a>
                            </p>
                          </Col>
                          <Col md={3}>
                            {finished || !currentAbility ? (
                              <></>
                            ) : (
                              <button
                                onClick={handleFinishCourse}
                                type="button"
                                className="btn btn-primary btn-block btn-xl"
                              >
                                <i className="fa fa-check"></i> Completar curso
                              </button>
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
      {showUploadImage && (
        <UploadCourseImageModal
          show={true}
          onCancel={() => setShowUploadImage(false)}
          onConfirm={image => {
            setShowUploadImage(false)
            finishCourseWithImage(image)
          }}
        />
      )}
      {showUploadText && (
        <UploadCourseTextModal
          show={true}
          onCancel={() => setShowUploadImage(false)}
          onConfirm={text => {
            setShowUploadText(false)
            finishCourseWithText(text)
          }}
        />
      )}
      {showFinishAlert && (
        <SweetAlert
          title={`Curso completado`}
          success
          confirmBtnBsStyle="success"
          onConfirm={() => {
            setShowFinishAlert(false)
          }}
        >
          Su evidencia ha sido recibida y enviada a validación.
        </SweetAlert>
      )}
    </React.Fragment>
  )
}

export default SoftskillsUserCourseDetail
